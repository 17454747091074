import React, { useEffect, useState } from "react";
import { getConfig } from "../config";
import auth0 from 'auth0-js';
import Hero from "../components/Hero";
// import Loading from '../components/Loading'
import { isWebViewContext, postMessage } from "./webViewHelper";
import loading from "../assets/Soloist_UI_LoadingBall_Vertical.gif";

const { seamlessLoginPageClientId, domain, host } = getConfig();
console.log(getConfig());
const webAuth = new auth0.WebAuth({
  domain,
  clientID: seamlessLoginPageClientId,
  redirectUri: `${host}/mobilesso?sso_completed=true`,
  responseType: 'id_token'
});

const searchParams = new URLSearchParams(document.location.search)



export const callbackFromMobileApp = (json) => {

  localStorage.setItem('targetUri', json.redirectUri);
  try {
    const user_id = json.userId;
    const accessToken = json.accessToken;
    webAuth.login({
      email: `${user_id}@user.id`,
      password: accessToken
    });
  } catch (e) {
    console.log(e.stack);
    throw e;

  }
}

const isSsoCompleted = () => searchParams.get("sso_completed") === "true";

// mobile app will call this function
window.callbackFromMobileApp = callbackFromMobileApp;


const MobileSSOComponent = () => {
  const url = localStorage.getItem('targetUri');
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  useEffect(() => {
    const image = new Image();
    image.src = loading;
    image.onload = () => setIsImageLoaded(true);
  }, []);

  if (isSsoCompleted()) {
    window.location.href = `${url}`;
    return (
      <div className="loading-container">
        {isImageLoaded && (
          <>
            <img src={loading} alt="Loading" height="50px" />
            <h1 className="loading-text">We're just stretching our code legs, hang on</h1>
          </>
        )}
        <div className="box"></div>
      </div>

    );
  }

  if (!isWebViewContext()) { return (<><Hero /><div>This page can only be accessed from the mobile application</div></>) }

  postMessage({ "action": "mobilesso" });
  return (
    <div className="loading-container">
      {isImageLoaded && (
        <>
          <img src={loading} alt="Loading" height="50px" />
          <h1 className="loading-text">We're just stretching our code legs, hang on</h1>
        </>
      )}
      <div className="box"></div>
    </div>);
};

export default MobileSSOComponent; 
