import React, { useState, useEffect } from "react";
import loading from "../assets/Soloist_UI_LoadingBall_Vertical.gif";

const Loading = ({ children }) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  useEffect(() => {
    const image = new Image();
    image.src = loading;
    image.onload = () => setIsImageLoaded(true);
  }, []);

  return (
    <div className="loading-container">
      {isImageLoaded && (
        <>
          <img src={loading} alt="Loading" height="50px" />
          <h1 className="loading-text">{children}</h1>
        </>
      )}
      <div className="box"></div>
    </div>
  );
};

export default Loading;
